<template>
  <v-container fluid tag="section">
    <base-material-card icon="mdi-cog"> 
      <slot slot="title">Settings</slot>
      <slot slot="rightButtons" v-if="boxOffice.id">
        <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
      </slot>
      <div v-if="showEditToggle">
        <v-form ref="boxOffice">
          <text-field label="Name" v-model="editBoxOffice.name" :rules="[utils.requiredRule]">
            <slot slot="tooltip">This should be the name of your organisation.<br/>
                  A box office can have an unlimited number of events.<br/>
                  You can change the box office name at anytime.</slot> 
          </text-field>
          <text-field label="Path" v-model="editBoxOffice.path" :rules="[utils.requiredRuleNoSpaces]">
            <slot slot="tooltip">This will form part of the link (url) that your customers will use to buy tickets.<br/>
                  You can use any value and change it later.<br/>
            </slot> 
          </text-field>
          <text-field label="Email" v-model="editBoxOffice.email" :rules="[utils.emailRule]">
            <slot slot="tooltip">Your email for general correspondance.  You can set a seperate email for each event if you like.
            </slot> 
          </text-field>

          <div class="d-flex">
            <v-switch v-model="editBoxOffice.emailNotifications"/>
            <div class="pt-5" style="valign:middle">Email Notifications? 
              <tooltip left>Would you like to receive a copy of your ticket orders by email?
                <br/>You can change this at any time.
              </tooltip>
            </div>
          </div>

          <v-row>
            <v-col cols="auto">Discount Codes</v-col>
            <v-col cols="auto">
              <v-row  v-for="(discount, i) in editBoxOffice.discountsArray" :key="i">
                <v-col>
                  <text-field label="Code" v-model="editBoxOffice.discountsArray[i].code">
                    <slot slot="tooltip">If you would like to offer discounts to some of your customers, <br/>
                     please enter a code and a percentage discount here.</slot> 
                  </text-field>
                </v-col>
                <v-col>
                  <text-field :rules="[utils.priceRule]" suffix="%" label="Percent" v-model="editBoxOffice.discountsArray[i].percent">
                    <slot slot="tooltip">Enter the percentage discount here.</slot> 
                  </text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <text-field label="Webhook" v-model="editBoxOffice.webhook">
            <slot slot="tooltip">This webhook will be called for every sucesssful order</slot> 
          </text-field>
          
          <v-label>Logo Image
            <tooltip top>
              Your logo will appear above your event picture for all your events and on your Box Office home page
            </tooltip>
          </v-label>
          <br>
          <img v-if="logoImage" :src="getCloudinaryImage({url: logoImage, width: 110})">
          <select-image-button :image="logoImage" class="mb-4" :boxOffice="editBoxOffice" @selectImage="selectLogoImage" @deleteImage="deleteLogoImage"/> 
          
          <v-label>Box Office Image
            <tooltip top>
              You can customise your Box Office home page by uploading a picture
            </tooltip>
          </v-label>
          <br>
          <img v-if="boxOfficeImage" :src="getCloudinaryImage({url: boxOfficeImage, width: 110})">
          <select-image-button :image="boxOfficeImage" class="mb-4" :boxOffice="editBoxOffice" @selectImage="selectBoxOfficeImage" @deleteImage="deleteBoxOfficeImage"/> 
          
          <v-label>Background Image
            <tooltip top>
              You can customise your Box Office home page by uploading a background picture
            </tooltip>
          </v-label>
          <br>
          <img v-if="backgroundImage" :src="getCloudinaryImage({url: backgroundImage, width: 110})">
          <select-image-button :image="backgroundImage" :boxOffice="editBoxOffice" @selectImage="selectBackgroundImage" @deleteImage="deleteBackgroundImage"/> 

          <div class="mt-4">
            <v-label>Colors
              <tooltip right>
                Choose a background and text color for your Box Office home page
              </tooltip>
            </v-label>
          </div>
          <select-colours :backgroundColour="boxOffice.backgroundColour" :textColour="boxOffice.textColour" @selectColours="selectColours"/> 
        </v-form>
        <div class="mt-4 mb-1">
          <v-label>Description
            <tooltip right>
                Enter a description for your Box Office home page
            </tooltip>
          </v-label>
        </div>
        <rich-text-editor v-model="editBoxOffice.description"/>
        <v-row class="mt-5">
          <v-col cols="auto">
            <v-btn color="success" @click="saveAndClose">Save</v-btn>
            <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
          </v-col>
          <v-spacer/>
          <v-col cols="auto">
            <v-btn class="mr-0" color="error" @click="deleteBoxOffice">Delete</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="3">Your Homepage</v-col>
          <v-col cols="9">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a :href="utils.makeBoxOfficeURL(boxOffice)" target="_blank">{{utils.makeBoxOfficeURL(boxOffice)}}</a>
                <v-icon v-on="on" light class="ml-4" @click.stop.prevent="copyPath">mdi-content-copy</v-icon>
                <input type="hidden" id="pathToCopy" :value="utils.makeBoxOfficeURL(boxOffice)">
              </template>
              <slot>Click to copy URL</slot>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Name</v-col>
          <v-col cols="9">{{boxOffice.name}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Email</v-col>
          <v-col cols="9">{{boxOffice.email}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Email Notifications</v-col>
          <v-col cols="9">{{boxOffice.emailNotifications}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Path</v-col>
          <v-col cols="9">{{boxOffice.path}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Discount Codes</v-col>
          <v-col cols="9">
            <v-row  v-for="(discount, i) in boxOffice.discountsArray" :key="i">
              <v-col cols="auto">{{discount.code}}</v-col>
              <v-col cols="auto">{{discount.percent}}%</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Webhook</v-col>
          <v-col cols="9">{{boxOffice.webhook}}</v-col>
        </v-row>
        <v-row v-if="boxOffice.logoImage">
          <v-col class="pr-0" cols="3">Logo Image</v-col>
          <v-col cols="9">
          <img :src="getCloudinaryImage({url: boxOffice.logoImage, width: 110})">
        </v-col>
        </v-row>
        <v-row v-if="boxOffice.boxOfficeImage">
          <v-col class="pr-0" cols="3">BoxOffice Image</v-col>
          <v-col cols="9">
          <img :src="getCloudinaryImage({url: boxOffice.boxOfficeImage, width: 110})">
        </v-col>
        </v-row>
        <v-row v-if="boxOffice.backgroundImage">
          <v-col class="pr-0" cols="3">Background Image</v-col>
          <v-col cols="9">
            <img :src="getCloudinaryImage({url: boxOffice.backgroundImage, width: 110})">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Colors</v-col>
          <v-col cols="9"><v-sheet class="text-center text-justify-center" height="100%" :color="boxOffice.backgroundColour" :style="'color:' + boxOffice.textColour"><pre class="pt-0">{{boxOffice.textColour}}</pre></v-sheet></v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">Description<br/>
          <span v-html="boxOffice.description"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Stripe payments</v-col>
          <v-col cols="9">
            <v-btn @click="goToManager()" color="secondary">Openstage Manager</v-btn>
            <p>You must have connected your Box Office to Stripe to sell tickets. To do this, visit <a @click="goToManager()">Openstage Manager</a> > Settings > Artist Settings and click on the Payment tabs.</p>
          </v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-container>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import Store from '@/services/Store.js';
import { getCloudinaryImage } from '@/helpers/cloudinary'

  export default {
    name: 'BoxOffice',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      RichTextEditor: () => import('./components/RichTextEditor2'),
      SelectImageButton: () => import('./components/SelectImageButton'),
      SelectColours: () => import('./components/SelectColours'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      },
      managerUrl() {
        return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_MANAGER_DOMAIN}`
      },
    },

    data () {
      return {
        getCloudinaryImage,
        currentBoxOfficeId: Store.boxOffice.id,
        editBoxOffice: {},
        backgroundImage: null,
        logoImage: null,
        boxOfficeImage: null,
        boxOffice: {},
        user: {},
        showEditToggle: false,
      }
    },

    created() {
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      var id = params.get("state");
      if (!id) {
        id = this.$route.params.id;
      }
      this.boxOffice.id = id;
      this.load();
    },
    
    methods: {
      goToManager() {
        window.open(this.managerUrl, '_blank')
      },

      pageIdRule(value) {
        if (!value) {
          return true;
        }
        const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        if (pattern.test(value.trim())) {
          return true;
        }
        return 'Invalid PageId.';
      },
      
      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.loadDiscounts()
        this.images = await Api.post(this, "Image", "list", this.boxOffice);
      },

      loadDiscounts() {
        this.boxOffice.discountsArray=[];
        if (this.boxOffice.discounts) {
          for (const [key, value] of Object.entries(this.boxOffice.discounts)) {
            this.boxOffice.discountsArray.push({code:key, percent:value})
          }
        }
      },

      connected(boxOffice) {
        this.boxOffice = boxOffice;
        this.load();
      },

      async save() {
        this.editBoxOffice.discounts = {}
        if (this.editBoxOffice.discountsArray) {
          for (var i=0; i<this.editBoxOffice.discountsArray.length; i++) {
            if (this.editBoxOffice.discountsArray[i].code) {
              this.editBoxOffice.discounts[this.editBoxOffice.discountsArray[i].code] = this.editBoxOffice.discountsArray[i].percent
            }
          }
        } 
        this.boxOffice = await Api.post(this, "BoxOffice", "update", this.editBoxOffice);
        this.loadDiscounts()
      },

      async saveAndClose() {
        if (! await Utils.validate(this.$refs.boxOffice) ) {
          return;
        }
        await this.save();
        this.showEditToggle = false;
      },

      async selectColours(backgroundColour, textColour) {
        this.editBoxOffice.textColour = textColour;
        this.editBoxOffice.backgroundColour = backgroundColour;
      },

      showEdit(show) {
        if (show) {
          this.editBoxOffice = Utils.jsonCopy(this.boxOffice)
          this.backgroundImage = this.editBoxOffice.backgroundImage
          this.logoImage = this.editBoxOffice.logoImage
          this.boxOfficeImage = this.editBoxOffice.boxOfficeImage
          if (!this.editBoxOffice.discountsArray) this.editBoxOffice.discountsArray = []
          this.editBoxOffice.discountsArray.push({code:"",percent:""})
        }
        this.showEditToggle = show;
      },

      selectBackgroundImage(image) {
        this.backgroundImage = image;
        this.editBoxOffice.backgroundImage = image;
      },

      selectLogoImage(image) {
        this.logoImage = image;
        this.editBoxOffice.logoImage = image;
      },

      selectBoxOfficeImage(image) {
        this.boxOfficeImage = image;
        this.editBoxOffice.boxOfficeImage = image;
      },

      deleteBackgroundImage() {
        this.backgroundImage = '';
        this.editBoxOffice.backgroundImage = '';
      },

      deleteLogoImage() {
        this.logoImage = '';
        this.editBoxOffice.logoImage = '';
      },

      deleteBoxOfficeImage() {
        this.boxOfficeImage = '';
        this.editBoxOffice.boxOfficeImage = '';
      },

      descriptionChanged(description) {
        this.editBoxOffice.description = description;
      },

      async deleteBoxOffice() {
        Store.boxOffice.name = null;
        Store.boxOffice.id = null;
        await Api.post(this, "BoxOffice", "delete", this.boxOffice);
        this.$router.push("/dashboard/BoxOffices"); 
      },

      copyPath () {
          let pathToCopy = document.querySelector('#pathToCopy')
          pathToCopy.setAttribute('type', 'text')    // ?? hidden ????
          pathToCopy.select()

          try {
            document.execCommand('copy');
          } catch (err) {
            alert('Sorry, unable to copy');
          }

          /* unselect the range */
          pathToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
      },
    }
  }

</script>
